<template>
  <div>
    <b-row class="mb-2">
      <b-col class="col-12 col-md-6 col-xl-4">
        <number-with-icon
          :number="$options.filters.price(widgets.total_sum)"
          icon="TagIcon"
          :title="$t('page.admin_subscriptions.widgets.total_sum')"
          color="primary"
        />
      </b-col>
      <b-col class="col-12 col-md-6 col-xl-4">
        <number-with-icon
          :number="widgets.total_subscriptions"
          icon="StarIcon"
          :title="$t('page.admin_subscriptions.widgets.total_subscriptions')"
          color="info"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          no-body
          class="mb-0"
        >
          <table-header
            search
            date
            :filter="filter"
            :search-updated="getPayments"
          />
          <b-table
            ref="teachersTable"
            striped
            :items="payments"
            responsive
            :fields="tableFields"
            primary-key="id"
            show-empty
            :empty-text="$t('page.students.search.labels.no-items')"
            :sort-by.sync="filter.sortBy"
            :sort-desc.sync="filter.sortDesc"
          >
            <template #cell(online)="data">
              <b-badge
                pill
                :variant="data.value ? 'success' : 'secondary'"
              >
                <span v-if="data.value">{{ $t('page.students.table.misc.online') }}</span>
                <span v-else>{{ $t('page.students.table.misc.offline') }}</span>
              </b-badge>
            </template>
            <template #cell(name)="data">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <store-image
                  v-if="data.item.image_id"
                  :store-id="data.item.image_id"
                  wrapper-class="table-avatar"
                />
                <b-avatar
                  v-else
                  size="25px"
                />
                <span class="d-block ml-1">{{ data.value }}</span>
              </div>
            </template>
            <template #cell(status)="data">
              <div class="d-flex flex-row justify-content-start align-items-center">
                <b-badge :variant="status(data.item.status).color">
                  {{ status(data.item.status).title }}
                </b-badge>
              </div>
            </template>
          </b-table>
          <table-footer
            :pagination-updated="getPayments"
            :pagination="pagination"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BTable, BCol, BRow, BAvatar, BBadge,
} from 'bootstrap-vue'
import StoreImage from '@/components/store/StoreImage.vue'
import NumberWithIcon from '@/components/widgets/stats/NumberWithIcon.vue'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import TableHeader from '@/components/page-elements/table/TableHeader.vue'
import { getUserFullName } from '../../../helpers'

export default {
  name: 'SubscriptionPayments',
  components: {
    TableHeader,
    TableFooter,
    NumberWithIcon,
    StoreImage,
    BCard,
    BTable,
    BCol,
    BRow,
    BAvatar,
    BBadge,
  },
  data: () => ({
    payments: [],
    pagination: {
      perPage: 15,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    widgets: {
      total_sum: 0,
      total_subscriptions: 0,
    },
    filter: {
      date_range: '',
      search: '',
      sortBy: 'created',
      sortDesc: true,
    },
  }),
  computed: {
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'id',
          label: this.$t('page.admin_subscriptions.table.labels.id'),
          sortable: true,
        },
        {
          key: 'user.username',
          label: this.$t('page.admin_subscriptions.table.labels.name'),
          sortable: true,
          formatter: (value, key, payment) => getUserFullName(payment.user),
        },
        {
          key: 'tariff.name',
          label: this.$t('page.admin_subscriptions.table.labels.plan_name'),
          sortable: true,
        },
        {
          key: 'total_sum',
          label: this.$t('page.admin_subscriptions.table.labels.price'),
          sortable: true,
          formatter: value => this.$options.filters.price(value),
        },
        {
          key: 'status',
          label: this.$t('page.admin_subscriptions.table.labels.status'),
          sortable: true,
        },
        {
          key: 'created',
          label: this.$t('page.admin_subscriptions.table.labels.created_at'),
          sortable: true,
          formatter: (value, key, row) => (row.created ? this.$options.filters.dateTime(row.created) : this.$t('no_data')),
        },
      ]
    },
    statuses() {
      return {
        10: {
          title: 'Новый',
          color: 'warning',
        },
        20: {
          title: 'Оплачен',
          color: 'info',
        },
        30: {
          title: 'Выполнен',
          color: 'success',
        },
        40: {
          title: 'Отменён',
          color: 'danger',
        },
      }
    },
  },
  watch: {
    sortBy() {
      this.getPayments()
    },
  },
  async created() {
    await this.getPayments()
    await this.getWidgetsInfo()
  },
  methods: {
    async getPayments() {
      const { items, _meta } = await this.$http.get('/order/index', {
        params: {
          sort: this.sortBy,
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          singleField: this.filter.search,
          created: this.filter.date_range,
          expand: 'tariff,user.usersStudent,user.usersTeachers',
        },
      })
      this.pagination.totalCount = _meta.totalCount
      this.payments = items
    },
    status(status) {
      const stat = parseInt(status, 10)
      if (!this.statuses[stat]) return { title: stat }
      return this.statuses[stat]
    },
    async getWidgetsInfo() {
      const dates = this.filter.date_range.split(',')
      const { sum } = await this.$http.get('/order/sum-period', {
        params: {
          dateFrom: dates[0],
          dateTo: dates[1],
        },
      })
      this.widgets.total_sum = sum
      const { count } = await this.$http.get('/subscription/count', {
        params: {
          is_active: 1,
        },
      })
      this.widgets.total_subscriptions = count
    },
  },
}
</script>

<style scoped>

</style>
